/*-------------------------------------------
  -- Navbar
-------------------------------------------*/

#ts-header {
    box-shadow: 0 .125rem .3125rem rgba(0, 0, 0, .1);
}

#ts-header:not(.fixed-top) {
    z-index: 3;
    position: relative;
}

#ts-header.ts-expanded {
    max-height: 100%;
    overflow-y: auto;
}

/*1st level*/

ul.navbar-nav {
    margin-top: .5rem;
}

ul.navbar-nav .nav-item {
    position: relative;
    border-bottom: .0625rem solid rgba(25, 25, 25, .1);
    transition: .3s ease;
}

ul.navbar-nav .nav-item.ts-active {
    background-color: rgba(25, 25, 25, .08);
    border-radius: .25rem;
}

ul.navbar-nav .nav-item:hover > ul.ts-child {
    opacity: 1;
    pointer-events: auto;
}

ul.navbar-nav .nav-link {
    color: rgba(25, 25, 25, .5);
}

ul.navbar-nav .nav-link:hover {
    color: rgba(25, 25, 25, .8);
}

ul.navbar-nav .nav-link.active {
    color: #191919;
}

ul.navbar-nav > .nav-item .nav-link {
    padding-left: .5rem;
    padding-bottom: 0;
    padding-top: 0;
}

ul.navbar-nav > .nav-item > .nav-link {
    margin-right: .5rem;
}

ul.navbar-nav > .nav-item > ul.ts-child > .nav-item:first-child > .nav-link {
    /* 2nd level up arrow on first item */
}

ul.navbar-nav > .nav-item > ul.ts-child > .nav-item:first-child > .nav-link:after {
    content: "";
    width: 0;
    height: 0;
    border-width: 0 .25rem .25rem .25rem;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -0.1875rem;
    left: 1rem;
    transition: .3s ease;
}

ul.navbar-nav > .nav-item > ul.ts-child > .nav-item:first-child > .nav-link:hover:after {
    border-color: transparent transparent #f3f3f3 transparent;
}

ul.navbar-nav .nav-item.ts-has-child:hover > .ts-child {
    height: auto;
}

/* 1st level arrow down */

ul.navbar-nav > .ts-has-child > .nav-link {
    padding-right: 1.25rem;
}

ul.navbar-nav > .ts-has-child > .nav-link:after {
    color: #191919;
    content: "\f0dd";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: .75rem;
    top: -0.125rem;
}

ul.navbar-nav > .ts-active > .nav-link:after {
    content: "\f0d8";
}

ul.navbar-nav > .ts-active .ts-active:after {
    content: "\f0d8" !important;
}

/*2nd level*/

ul.navbar-nav ul.ts-child {
    border-radius: .125rem;
    list-style: none;
    min-width: 13rem;
    padding-left: 0;
    height: 0;
    width: 100%;
    text-align: left;
    overflow: hidden;
    transition: .3s ease;
    pointer-events: none;
    z-index: 2;
}

ul.navbar-nav ul.ts-child .nav-item {
    border-bottom: .0625rem solid rgba(25, 25, 25, .1);
}

ul.navbar-nav ul.ts-child .nav-item:first-child {
    border-top: .0625rem solid rgba(25, 25, 25, .1);
}

ul.navbar-nav ul.ts-child .nav-item:last-child {
    border-bottom: none;
}

ul.navbar-nav ul.ts-child > .nav-item:hover > .nav-link {
    background-color: rgba(25, 25, 25, .05);
}

ul.navbar-nav ul.ts-child .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
}

ul.navbar-nav ul.ts-child .ts-has-child:after {
    color: rgba(25, 25, 25, .2);
    content: "\f0dd";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: .75rem;
    top: -0.125rem;
}

/*3rd level*/

ul.navbar-nav ul.ts-child ul.ts-child {
    top: 0;
    left: 0;
    margin-left: 0;
}

ul.navbar-nav .nav-item:hover {
    background-color: rgba(25, 25, 25, .05);
}

.navbar-dark {
    color: #fff;
}

.navbar-dark #ts-primary-navigation {
    background-color: #171717;
}

.navbar-dark #ts-secondary-navigation {
    background-color: #000;
}

.navbar-dark #ts-secondary-navigation .form-control,
.navbar-dark #ts-secondary-navigation .custom-select {
    color: rgba(255, 255, 255, .5);
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5);
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .8);
}

.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-item.ts-active {
    background-color: rgba(255, 255, 255, .08);
}

.navbar-dark .navbar-nav > .nav-item > ul.ts-child > .nav-item:first-child > .nav-link:hover:after {
    border-color: transparent transparent #000;
}

.navbar-dark .navbar-nav > .nav-item > ul.ts-child > .nav-item:first-child > .nav-link:after {
    border-color: transparent transparent #000;
}

.navbar-dark .navbar-nav > .ts-has-child > .nav-link:after {
    color: #fff;
}

.navbar-dark .navbar-nav ul.ts-child .ts-has-child:after {
    color: rgba(255, 255, 255, .6);
}

.navbar-dark [class*="border-"] {
    border-color: rgba(255, 255, 255, .2) !important;
}

.navbar-collapse.collapse.show {
    max-height: 75vh;
    overflow-y: auto;
}

#ts-primary-navigation {
    background-color: #fff;
    font-size: .875rem;
    font-weight: 400;
    padding-bottom: 1rem;
    padding-top: 1rem;
    transition: .3s ease;
    z-index: 999;
}

#ts-secondary-navigation {
    background-color: #f6f6f6;
    border-bottom: .0625rem solid rgba(0, 0, 0, .1);
    color: #9e9e9e;
    font-size: .75rem;
}

#ts-secondary-navigation a {
    color: #9e9e9e;
    display: inline-block;
}

#ts-secondary-navigation input {
    font-size: .75rem;
}

#ts-secondary-navigation .form-control, #ts-secondary-navigation .custom-select {
    border: none;
    border-radius: 0;
    color: rgba(0, 0, 0, .5);
    min-height: inherit;
}
