.breadcrumb {
    background-color: transparent;
    margin-bottom: 1rem;
    padding: 0;
    font-size: .8125rem;
}

.breadcrumb-item {
    opacity: .5;
}

.breadcrumb-item.active {
    color: #191919;
    opacity: 1;
}

.breadcrumb-item + .breadcrumb-item:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: .625rem;
    opacity: .5;
}